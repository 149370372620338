import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import { Image } from "../../types";
import { parse } from "../../utils/component";

interface HorizontalGalleryProps {
  images: Image[];
  visibleImages: number;
}

const HorizontalGallery: React.FC<HorizontalGalleryProps> = ({ images, visibleImages }) => {
  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const [isOpen, setOpen] = useState<boolean>(false);

  const extraImageCount = (images ?? []).length - visibleImages;

  const handleImageClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();

    openGallery(parseInt(e.currentTarget.dataset.index ?? "0"));
  };

  const handleMoreClick: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    e.preventDefault();

    openGallery(visibleImages - 1);
  };

  const openGallery = (index: number) => {
    setPhotoIndex(index);
    setOpen(true);
  };

  return (
    <div className="horizontal-gallery">
      {images.slice(0, visibleImages).map((images, index) => (
        <div key={index} className="horizontal-gallery__thumbnail">
          <a onClick={handleImageClick} className="horizontal-gallery__anchor" title="View image">
            <img src={parse(images.url)} alt={parse(images.altText)} className="horizontal-gallery__image" />
          </a>
          {extraImageCount && index === visibleImages - 1 && (
            <span onClick={handleMoreClick} className="gallery__thumbnail--extra" data-imagecount={`+${extraImageCount}`}></span>
          )}
        </div>
      ))}
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]?.url ?? ""}
          imageTitle={images[photoIndex]?.title}
          nextSrc={images[(photoIndex + 1) % images.length].url ?? undefined}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].url ?? undefined}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </div>
  );
};

export default HorizontalGallery;
