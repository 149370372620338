import React from "react";
import Icon from "../icon";
import Link from "../link";

interface InfoMessageProps {
  title: string;
  text: string;
  buttonLink: string;
  buttonText: string;
}

const InfoMessage: React.FC<InfoMessageProps> = ({ title, text, buttonLink, buttonText }) => (
  <div className="info-message">
    <div className="info-message__icons">
      <div className="list-inline">
        <ul>
          <li>
            <Icon name="plane" />
          </li>
          <li>+</li>
          <li>
            <Icon name="taxi" />
          </li>
          <li>+</li>
          <li>
            <Icon name="bed-booking" />
          </li>
          <li>+</li>
          <li>
            <Icon name="ticket" />
          </li>
        </ul>
      </div>
    </div>
    <div className="heading-seperator">
      <h2 className="info-message__heading">{title}</h2>
    </div>
    <p dangerouslySetInnerHTML={{ __html: text }}></p>
    <Link path={buttonLink} title={buttonText} className="cta">
      {buttonText}
    </Link>
  </div>
);

export default InfoMessage;
