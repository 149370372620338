import { BookingPackageItem } from "@qite/tide-client/build/types";
import { ServiceType } from "@qite/tide-client";
import { format } from "date-fns";
import React, { useContext } from "react";
import GlobalContext, { GlobalContextType } from "../../../contexts/global-provider";
import { getDateOnly, getDateOnlyString, getWeekDay } from "../../../utils/date-utils";
import { localeMapper } from "../../../utils/language-mapper";
import translate from "../../../utils/translate";
import Icon from "../../icon";
import translations from "../translations.json";
import { navigate } from "gatsby";
import { first } from "lodash";
import Tooltips from "../../tooltips";

interface SummaryPanelItemProps {
  packageDetail: BookingPackageItem;
}

const SummaryPanelItem: React.FC<SummaryPanelItemProps> = ({ packageDetail }) => {
  const { language, affiliate } = useContext<GlobalContextType>(GlobalContext);

  const dateConfirmed = packageDetail?.allotment?.customAllotmentStatus === "Datum bevestigd";
  const eventId = packageDetail?.allotment?.tourCode ? first(packageDetail.allotment.tourCode.split("|")) : "";

  const HandleBooking: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    const querystring = `?ProductCode=${packageDetail?.code}&CatalogId=${packageDetail?.catalogueId}&From=${packageDetail?.fromDate}&To=${
      packageDetail?.toDate
    }&Tourcode=${packageDetail?.allotment?.tourCode}&EventId=${eventId}&ServiceTypes=${packageDetail.includedServiceTypes}${
      affiliate && affiliate !== "" ? "&vrp=" + affiliate : ""
    }`;
    navigate(`/boeking${querystring}`);
  };

  const GetListItemTitle = (item: BookingPackageItem) => {
    if (item.includedServiceTypes.includes(ServiceType.flight)) {
      return translate(translations, language, "FLIGHTPACKAGE");
    }

    if (item.includedServiceTypes.includes(ServiceType.hotel)) {
      return translate(translations, language, "HOTELPACKAGE");
    }

    return translate(translations, language, "TICKETPACKAGE");
  };

  return (
    <div className="summary-panel__item">
      <div className="productcard">
        <div className="productcard__body">
          <div className="arrow-list">
            <ul>
              <li>
                {getWeekDay(localeMapper(language), getDateOnly(new Date(packageDetail.fromDate)))} {getDateOnlyString(packageDetail.fromDate)}
              </li>
              <li>
                {getWeekDay(localeMapper(language), getDateOnly(new Date(packageDetail.toDate)))} {getDateOnlyString(packageDetail.toDate)}
              </li>
            </ul>
            <div className="tooltips">
              <div className="tooltips__tooltip">
                <Icon name="tooltip-white" />
                <div className="tooltips__label">
                  <span className="tooltips__label-text">{translate(translations, language, "DATE_REMARK")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="productcard__body-specs">
            <div className="productcard__list">
              <div className="productcard__listitem">
                <div className="productcard__listitem-header">
                  <span className="productcard__listitem-property">{GetListItemTitle(packageDetail)}</span>
                  <Tooltips
                    plane={packageDetail.includedServiceTypes.includes(ServiceType.flight)}
                    hotel={packageDetail.includedServiceTypes.includes(ServiceType.hotel)}
                    ticket={packageDetail.includedServiceTypes.includes(ServiceType.event)}
                  />
                </div>
              </div>
              <div className="productcard__listitem">
                <div className="productcard__listitem-header">
                  <span className="productcard__listitem-property">
                    {translate(translations, language, process.env.WEBSITE === "Sportreizen" ? "EVENTDATE" : "MATCHDATE")}
                  </span>
                  <span className="productcard__listitem-value">
                    {!dateConfirmed && (
                      <div className="tooltips">
                        <div className="tooltips__tooltip">
                          <Icon name="info-triangle" />
                          <div className="tooltips__label">
                            <span className="tooltips__label-text">{translate(translations, language, "DATE_NOT_CONFIRMED")}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {format(new Date(packageDetail.allotment.startDate), "dd/MM/yyyy")}
                  </span>
                </div>
              </div>
              <div className="productcard__listitem">
                <div className="productcard__listitem-body">
                  <div className="pricing">
                    {translate(translations, language, "FROM_SHORT")}
                    <span className="pricing__price">&euro;&nbsp;{packageDetail.averagePricePerPerson.toFixed(2)}</span>
                    {translate(translations, language, "PER_PERSON_SHORT")}
                  </div>
                  <button type="button" className="cta" title={translate(translations, language, "BOOKING_PACKAGE")} onClick={HandleBooking}>
                    {translate(translations, language, "BOOKING_PACKAGE")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryPanelItem;
