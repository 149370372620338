import React, { useContext, useEffect, useState } from "react";
import { TideItemForHardcodedUrl, TideItemForWebsite } from "../../../types";
import { findGameById, findSeasonGameById, getChildItemsWithTypename, parse } from "../../../utils/component";
import PackageContext, { PackageContextType } from "../../../contexts/package-provider";
import Trust from "../../trust";
import InfoMessage from "../../info-message";
import PreloaderSlider from "../../preloader/slider";
import SummaryPanelItem from "./item";
import { first } from "lodash";
import { ComponentContext, ComponentContextType } from "../../../contexts/component-provider";
import SummaryPanelHardcodedItem from "./hardcoded-item";

interface SummaryPanelProps {
  website?: TideItemForWebsite;
  title?: string;
}

const SummaryPanel: React.FC<SummaryPanelProps> = ({ website, title }) => {
  const { packageDetail, packagesLoading, packages } = useContext<PackageContextType>(PackageContext);
  const { games } = useContext<ComponentContextType>(ComponentContext);
  const [hardcodedUrls, setHardcodedUrls] = useState<TideItemForHardcodedUrl[]>([]);

  let className = "summary-panel summary-panel--products";
  if (!packageDetail && packagesLoading) {
    className = "summary-panel summary-panel--products summary-panel--initial-loading";
  }
  if (!packageDetail && !packagesLoading) {
    className = "summary-panel summary-panel--offer-request";
  }

  useEffect(() => {
    if (packageDetail && packageDetail.allotment) {
      const eventId = first(packageDetail.allotment.tourCode.split("|"));
      if (!eventId) return;
      const game = findGameById(games, eventId);
      if (!game) return;
      const seasonGame = findSeasonGameById(game, eventId);
      if (!seasonGame) return;
      setHardcodedUrls(getChildItemsWithTypename("TideItemForHardcodedUrl", seasonGame) as TideItemForHardcodedUrl[]);
    }
  }, [packageDetail]);

  return (
    <div className={className}>
      {!packageDetail && (
        <>
          {packagesLoading && (
            <>
              <PreloaderSlider />
            </>
          )}
          {!packagesLoading && (
            <>
              <div className="summary-panel__region summary-panel__region--no-icon">
                <div className="summary-panel__region-header">
                  <InfoMessage
                    title="Interesse in deze reis?"
                    text="Deze reis is <strong>momenteel niet boekbaar</strong>.<br/> <strong>Vraag hieronder jouw offerte aan</strong> &amp; we brengen je op de hoogte zodra je deze reis kan boeken."
                    buttonLink="groepsreizen/"
                    buttonText="Vraag offerte aan"
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
      {packageDetail && (
        <>
          {packages && packages.map((packageItem, i) => <SummaryPanelItem packageDetail={packageItem} key={`summaryPanelItem${i}`} />)}
          {hardcodedUrls &&
            hardcodedUrls.map((hardcodedUrl, i) => (
              <SummaryPanelHardcodedItem key={i} data={hardcodedUrl} eventDate={packageDetail.allotment.startDate} />
            ))}
          <Trust
            styling="summary-panel__trust"
            linkSgr={parse(website?.content?.general?.linkSgr)}
            linkThuiswinkelWaarborg={parse(website?.content?.general?.linkThuiswinkelWaarborg)}
            showStars={false}
            key="trust"
          />
        </>
      )}
    </div>
  );
};

export default SummaryPanel;
