import { ServiceType } from "@qite/tide-client";
import { graphql } from "gatsby";
import { isEmpty } from "lodash";
import React, { useContext } from "react";
import PackageContext, { PackageContextType } from "../../../contexts/package-provider";
import { Image, TideItemForRating, TideItemForWebsite } from "../../../types";
import { parse } from "../../../utils/component";
import Collapsible from "../../collapsible";
import HorizontalGallery from "../../horizontal-gallery";
import SummaryList from "../../summary-list";
import SummaryPanel from "../../summary-panel/product";
import Tooltips from "../../tooltips";

export enum IntroType {
  Normal,
  Wildcard,
}

interface IntroProps {
  title?: string;
  tournamentTitle?: string;
  images?: Image[];
  eventLocation?: string;
  text?: string;
  website?: TideItemForWebsite;
}

const Intro: React.FC<IntroProps> = ({ title, tournamentTitle, images, eventLocation, text, website }) => {
  const { packageDetail } = useContext<PackageContextType>(PackageContext);
  const list = [];
  if (tournamentTitle && !isEmpty(tournamentTitle)) {
    list.push(tournamentTitle);
  }
  if (eventLocation && !isEmpty(eventLocation)) {
    list.push(eventLocation);
  }
  return (
    <div className="product-intro">
      <div className="product-intro__container">
        <div className="product-intro__text">
          <div className="heading-seperator">
            {packageDetail && (
              <Tooltips
                plane={packageDetail.includedServiceTypes.includes(ServiceType.flight)}
                hotel={packageDetail.includedServiceTypes.includes(ServiceType.hotel)}
                ticket={packageDetail.includedServiceTypes.includes(ServiceType.event)}
              />
            )}
            <h1 className="product-intro__heading">{title}</h1>
            <div className="product-intro__summary-list">
              <SummaryList list={list} />
            </div>
          </div>

          <Collapsible text={parse(text)} isProductPage={true}/>

          {images && images?.length > 0 && <HorizontalGallery images={images} visibleImages={3} />}
        </div>

        <div className="product-intro__summary-panel">
          <SummaryPanel website={website} title={title} key="SummaryPanel" />
        </div>
      </div>
    </div>
  );
};

export default Intro;

export const query = graphql`
  fragment TideItemForIntroComponentFragment on TideItemForIntroComponent {
    __typename
    id
    content {
      general {
        title
        text
      }
    }
  }
`;
