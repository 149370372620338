import React, { createContext, useEffect, useState } from "react";
import { TideItemForHotel } from "../../types";

interface HotelProviderProps {
  hotels: TideItemForHotel[];
}

export interface HotelContextType {
  cmsHotels: TideItemForHotel[];
}

const initialState: HotelContextType = {
  cmsHotels: [],
};

const HotelContext = createContext(initialState);

export const HotelProvider: React.FC<HotelProviderProps> = ({ hotels, children }) => {
  const [cmsHotels, setCmsHotels] = useState<TideItemForHotel[]>([]);

  useEffect(() => {
    setCmsHotels(hotels);
  }, [hotels]);

  return (
    <HotelContext.Provider
      value={{
        cmsHotels,
      }}
    >
      {children}
    </HotelContext.Provider>
  );
};

export default HotelContext;
