import React from "react";
import { format } from "date-fns";
import { TideItemForHardcodedUrl } from "../../../types";
import { getWeekDay } from "../../../utils/date-utils";
import { localeMapper } from "../../../utils/language-mapper";
import { useContext } from "react";
import GlobalContext, { GlobalContextType } from "../../../contexts/global-provider";
import Icon from "../../icon";
import translate from "../../../utils/translate";
import translations from "../translations.json";
import Tooltips from "../../tooltips";
import { first } from "lodash";

interface SummaryPanelHardcodedItemProps {
  data: TideItemForHardcodedUrl;
  eventDate: string;
}

const NavigateToExternal = (e: any, url: string) => {
  e.preventDefault();
  if (url === "") return;
  if (!url.startsWith("http")) {
    url = "https://" + url;
  }
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const SummaryPanelHardcodedItem: React.FC<SummaryPanelHardcodedItemProps> = ({ data, eventDate }) => {
  const { language } = useContext<GlobalContextType>(GlobalContext);
  return (
    <div className="summary-panel__item">
      <div className="productcard">
        <div className="productcard__body">
          <div className="arrow-list">
            <ul>
              <li>
                {getWeekDay(localeMapper(language), new Date(eventDate))} {format(new Date(eventDate), "dd/MM/yyyy")}
              </li>
              <li>
                {getWeekDay(localeMapper(language), new Date(eventDate))} {format(new Date(eventDate), "dd/MM/yyyy")}
              </li>
            </ul>
            <div className="tooltips">
              <div className="tooltips__tooltip">
                <Icon name="tooltip-white" />
                <div className="tooltips__label tooltips__label--down">
                  <span className="tooltips__label-text">{translate(translations, language, "DATE_REMARK")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="productcard__body-specs">
            <div className="productcard__list">
              <div className="productcard__listitem">
                <div className="productcard__listitem-header">
                  <span className="productcard__listitem-property">{data.content?.general?.title}</span>
                  <Tooltips
                    plane={false}
                    hotel={false}
                    ticket={true}
                    bus={first(data.content?.general?.transportIcon) === "bus"}
                    boat={first(data.content?.general?.transportIcon) === "boat"}
                  />
                  {/* <span className="productcard__listitem-value"></span> */}
                </div>
              </div>
              <div className="productcard__listitem">
                <div className="productcard__listitem-header">
                  <span className="productcard__listitem-property">
                    {translate(translations, language, process.env.WEBSITE === "Sportreizen" ? "EVENTDATE" : "MATCHDATE")}
                  </span>

                  <span className="productcard__listitem-value">{format(new Date(eventDate), "dd/MM/yyyy")}</span>
                </div>
              </div>
              <div className="productcard__listitem">
                <div className="productcard__listitem-body">
                  {data.content?.general?.price && (
                    <div className="pricing">
                      {translate(translations, language, "FROM_SHORT")}
                      <span className="pricing__price">&euro;&nbsp;{data.content.general.price.toFixed(2)}</span>
                      {translate(translations, language, "PER_PERSON_SHORT")}
                    </div>
                  )}
                  <button
                    type="button"
                    className="cta"
                    title={translate(translations, language, "BOOKING_PACKAGE")}
                    onClick={(e) => NavigateToExternal(e, data.content?.general?.externalUrl ?? "")}
                  >
                    {translate(translations, language, "BOOKING_PACKAGE")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryPanelHardcodedItem;
