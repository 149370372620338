import React from "react";
import Icon from "../../icon";

interface PreloaderSliderProps {}

const PreloaderSlider: React.FC<PreloaderSliderProps> = () => {
  return (
    <div className="preloader-slider">
      <div className="preloader-slider__icons">
        <div className="preloader-slider__focus-icon"></div>
        <div className="preloader-slider__icons-list">
          <Icon name="bed-booking"></Icon>
          <Icon name="ticket"></Icon>
          <Icon name="plane"></Icon>
          <Icon name="bed-booking"></Icon>
          <Icon name="ticket"></Icon>
          <Icon name="plane"></Icon>
          <Icon name="bed-booking"></Icon>
          <Icon name="ticket"></Icon>
          <Icon name="plane"></Icon>
        </div>
      </div>

      <div className="preloader-slider__text">
        <p className="preloader-slider__text-heading">Even geduld, wij zoeken naar</p>
        <div className="preloader-slider__text-body">
          <div className="preloader-slider__text-service">
            beschikbare vluchten
            <div className="preloader-slider__dots">
              <div className="preloader-slider__dot-1">.</div>
              <div className="preloader-slider__dot-2">.</div>
              <div className="preloader-slider__dot-3">.</div>
            </div>
          </div>
          <div className="preloader-slider__text-service">
            beschikbare hotels
            <div className="preloader-slider__dots">
              <div className="preloader-slider__dot-1">.</div>
              <div className="preloader-slider__dot-2">.</div>
              <div className="preloader-slider__dot-3">.</div>
            </div>
          </div>
          <div className="preloader-slider__text-service">
            beschikbare tickets
            <div className="preloader-slider__dots">
              <div className="preloader-slider__dot-1">.</div>
              <div className="preloader-slider__dot-2">.</div>
              <div className="preloader-slider__dot-3">.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreloaderSlider;
