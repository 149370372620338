import React from "react";

interface SummaryListProps {
  list?: string[];
}

const SummaryList: React.FC<SummaryListProps> = ({ list }) => {
  return (
    <div className="summary-list">
      <ul>{list && list.map((item, i) => <li key={i}>{item}</li>)}</ul>
    </div>
  );
};

export default SummaryList;
